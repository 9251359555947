/* eslint-disable unicorn/consistent-function-scoping */

import { convertLocale } from '@pe-libs/react-components/build/lib/helper';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import { getQueryParameters } from '#helpers/query';
import { setUserIntl } from '#modules/app/actions/settings';
import { getAffiliateUserData } from '#modules/auth/actions/profile';
import { wrapper } from '../../store';
import messages from '../messages';

const PeRegister = dynamic(() => import('#modules/auth/components/PeRegister'), { ssr: false });

const RegisterPage: NextPage = () => {
  const formatMessage = useIntlMessage();

  return (
    <>
      <Head>
        <title>{formatMessage(messages.seoTitle)}</title>
        <meta name="description" content={formatMessage(messages.seoDescription)} />
      </Head>
      <PeRegister type="affiliate" />
    </>
  );
};

RegisterPage.getInitialProps = wrapper.getInitialPageProps((store) => async (context) => {
  const lang = getQueryParameters(context.query, 'lang');
  const locale = convertLocale(lang, 'locale');

  store.dispatch(setUserIntl({ locale }));
  const profileId = getQueryParameters(context.query, 'id');

  await store.dispatch(getAffiliateUserData({ profileId }));

  return { props: {} };
});

export default RegisterPage;
